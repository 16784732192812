import {
  Box,
  Stack,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  IconButton,
  useDisclosure,
  Collapse,
  Flex,
  Text,
  Icon,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import { NavItem } from "../../../types";
import { NAV_ITEMS } from "../../../constants";
import { NavLink } from "../../atoms";
import { TrainingMenu } from "./NavHoverCards";

const DesktopNav = () => {
  const linkColor = useColorModeValue("brand.900", "brand.900");
  const linkHoverColor = useColorModeValue("#00a0d2", "#00a0d2");
  //const location = useLocation();
  // const { pathname } = location;
  // const activeRoute = pathname.replace("/", "");
  return (
    <Stack direction={"row"} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label} className="nav-item">
          <Popover trigger={"hover"} placement={"auto"}>
            <PopoverTrigger>
              <RouterLink to={navItem.href ?? "#"}>
                <NavLink
                  text={navItem.label}
                  linkColor={linkColor}
                  linkHoverColor={linkHoverColor}
                />
              </RouterLink>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                className="popover-content-box"
                boxShadow={
                  "0px 1px 25px -5px rgb(40 201 200 / 30%), 0 10px 10px -5px rgb(40 201 200 / 30%)"
                }
              >
                {navItem.label === "Training Programs" && <TrainingMenu />}
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const MobileNav = () => {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <Stack direction={"row"} spacing={4}>
      <IconButton
        onClick={onToggle}
        icon={
          isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
        }
        variant={"ghost"}
        aria-label={"Toggle Navigation"}
      />
      <Collapse in={isOpen} animateOpacity className="mob-nav-item">
        <Stack bg={useColorModeValue("white", "gray.800")} p={4}>
          {NAV_ITEMS.map((navItem) => (
            <MobileNavItem key={navItem.label} {...navItem} />
          ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? "#"}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} href={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

// export default DesktopNav;

export { DesktopNav, MobileNav };
