import {
  Button,
  // useToast,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  Select,
  Box,
  Heading,
  InputLeftElement,
  VStack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { TRAINING_TYPES } from "../../constants";
import { GradientText } from "../atoms";
import { BsPerson } from "react-icons/bs";
import { BsPhone } from "react-icons/bs";

const Enquiry = (props: any) => {
  // const toast = useToast();

  const {
    handleSubmit,
    register,
    // setValue,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = (values: any) => {};

  return (
    <Box>
      <Heading lineHeight={1.1} fontWeight={600} fontSize="26px">
        <GradientText title="Enquiry " />
      </Heading>
      {/* <Box border={"1px solid #E0E1E7"} p={4}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl isInvalid={errors.name ? true : false}>
            <FormLabel>Name</FormLabel>
            <InputGroup borderColor="#E0E1E7">
              <Input
                id="name"
                type="text"
                border={"1px solid #cbd5e0"}
                h={12}
                borderRadius={"0.375rem"}
                placeholder="Full Name"
                {...register("name", {
                  required: "Name is required",
                  minLength: {
                    value: 4,
                    message: "Minimum length should be 4",
                  },
                })}
              />
            </InputGroup>
            <FormErrorMessage>
              {errors.name && errors.name.message?.toString()}
            </FormErrorMessage>
          </FormControl>

          <FormControl w={"100%"} isInvalid={errors.type ? true : false}>
            <FormLabel>Training Type</FormLabel>
            <InputGroup borderColor="#E0E1E7">
              <Select
                placeholder="Select Training Type "
                {...register("type", {
                  required: "Type is required",
                })}
              >
                {TRAINING_TYPES.map((item, index) => (
                  <option value={item}>{item}</option>
                ))}
              </Select>
            </InputGroup>
            <FormErrorMessage>
              {errors.type && errors.type.message?.toString()}
            </FormErrorMessage>
          </FormControl>

          <FormControl w={"100%"} isInvalid={errors.mobileno ? true : false}>
            <FormLabel>Mobile No</FormLabel>
            <InputGroup borderColor="#E0E1E7">
              <Input
                id="mobileno"
                type="text"
                border={"1px solid #cbd5e0"}
                h={12}
                borderRadius={"0.375rem"}
                placeholder="Full Name"
                {...register("mobileno", {
                  required: "Mobile Number is required",
                })}
              />
            </InputGroup>
            <FormErrorMessage>
              {errors.mobileno && errors.mobileno.message?.toString()}
            </FormErrorMessage>
          </FormControl>

          <FormControl float="right">
            <Button
              variant="solid"
              bg="#244c70"
              color="white"
              _hover={{ bg: "#ffb26c" }}
              isLoading={isSubmitting}
              type="submit"
            >
              Enquire Now
            </Button>
          </FormControl>
        </form>
      </Box> */}
      <VStack
        spacing={5}
        alignItems={"flex-start"}
        bg="blackAlpha.200"
        borderRadius="lg"
        p={4}
        my={4}
      >
        <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
          <FormControl w={"100%"} isInvalid={errors.name ? true : false}>
            <FormLabel htmlFor="name" marginBottom={"8px"} fontWeight={600}>
              Your Name
            </FormLabel>
            <InputGroup borderColor="#E0E1E7">
              <InputLeftElement
                pointerEvents="none"
                padding={"16px"}
                children={<BsPerson color="gray.800" />}
              />
              <Input
                id="name"
                type="text"
                paddingLeft={"48px"}
                paddingRight={"16px"}
                w={"calc(100% - 16px)"}
                border={"1px solid #cbd5e0"}
                h={12}
                borderRadius={"0.375rem"}
                placeholder="Full Name"
                {...register("name", {
                  required: "Name is required",
                  minLength: {
                    value: 4,
                    message: "Minimum length should be 4",
                  },
                })}
              />
            </InputGroup>
            <FormErrorMessage>
              {errors.name && errors.name.message?.toString()}
            </FormErrorMessage>
          </FormControl>

          <FormControl w={"100%"} isInvalid={errors.mobile ? true : false}>
            <FormLabel htmlFor="mobile" marginBottom={"8px"} fontWeight={600}>
              Contact
            </FormLabel>
            <InputGroup borderColor="#E0E1E7">
              <InputLeftElement
                pointerEvents="none"
                padding={"16px"}
                children={<BsPhone color="gray.800" />}
              />
              <Input
                id="mobile"
                type="text"
                paddingLeft={"48px"}
                paddingRight={"16px"}
                w={"calc(100% - 16px)"}
                border={"1px solid #cbd5e0"}
                h={12}
                borderRadius={"0.375rem"}
                placeholder="Mobile Number"
                {...register("mobile", {
                  required: "Mobile Number is required",
                  pattern: {
                    value: /^[0-9]{10}$/i,
                    message: "Please valid 10 digit mobile number",
                  },
                })}
              />
            </InputGroup>
            <FormErrorMessage>
              {errors.mobile && errors.mobile.message?.toString()}
            </FormErrorMessage>
          </FormControl>

          <FormControl w={"100%"} isInvalid={errors.training ? true : false}>
            <FormLabel htmlFor="training" marginBottom={"8px"} fontWeight={600}>
              Training
            </FormLabel>
            <InputGroup borderColor="#E0E1E7">
              <Select
                id="training"
                w={"100%"}
                border={"1px solid #cbd5e0"}
                h={12}
                borderRadius={"0.375rem"}
                placeholder="Select Training"
                {...register("training", {
                  required: "Training is required",
                })}
              >
                {TRAINING_TYPES.map((item, index) => (
                  <option value={item}>{item}</option>
                ))}
              </Select>
            </InputGroup>
            <FormErrorMessage>
              {errors.training && errors.training.message?.toString()}
            </FormErrorMessage>
          </FormControl>

          <FormControl float="right">
            <Button
              variant="solid"
              bg="#244c70"
              color="white"
              _hover={{ bg: "#962cb2" }}
              isLoading={isSubmitting}
              type="submit"
            >
              Enquire Now
            </Button>
          </FormControl>
        </form>
      </VStack>
    </Box>
  );
};

export default Enquiry;
