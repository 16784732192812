import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Text } from "@chakra-ui/react";
import { BreadCrumbProps } from "../../types";

const BreadCrumb = (props: BreadCrumbProps) => {
  const {items} = props;
  return (
    <Box py={4} my={4} bg={'#962cb2'}>
      <Box className="genz-wrapper">
        <Breadcrumb separator={'/'}>
          {items.map((ele) => (
            <BreadcrumbItem color={'#FFF'} fontWeight={600}>
              {
                ele.link?
                  <BreadcrumbLink textTransform={"capitalize"} fontSize={'18px'} _hover={{color:'#00a0d2'}} href={ele.link}>{ele.label}</BreadcrumbLink>:
                  <Text textTransform={"capitalize"} fontSize={'18px'}>{ele.label}</Text>
              }
            </BreadcrumbItem>
          ))}
        </Breadcrumb>
      </Box>
    </Box>
  )
}

export default BreadCrumb;