import * as React from "react";

import { SimpleGrid, } from "@chakra-ui/react";
import { TRAINING_PROGRAMS } from "../../../constants";
import { TrainingMenuCard } from "../../atoms";

const TrainingMenu = () => {
    return (
        <SimpleGrid columns={2} spacing={3} paddingTop={2}>
            {Object.entries(TRAINING_PROGRAMS).map(([category, items]) => (
                <TrainingMenuCard
                    heading={category}
                    trainings={items}
                />
            ))}
        </SimpleGrid>
    );
};

export { TrainingMenu };
