
import {
    Text,

    Stack,

    Flex
} from '@chakra-ui/react';



const WeFeature = ({ text, icon, iconBg }: any) => {
    return (
        <Stack direction={'row'} align={'center'}>
            <Flex
                w={8}
                h={8}
                align={'center'}
                justify={'center'}
                rounded={'full'}
                bg={iconBg}>
                {icon}
            </Flex>
            <Text fontWeight={600}>{text}</Text>
        </Stack>
    );
};


export default WeFeature;