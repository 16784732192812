import React from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Icon,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import Enquiry from "../components/molecules/Enquiry";
import { GradientText } from "../components/atoms";
import BreadCrumb from "../components/atoms/BreadCrumb";
import { BreadCrumbItemProps } from "../types";
import { StatsCard } from "../components/molecules";
import { BsGlobeCentralSouthAsia, BsPersonVideo3, BsStopwatch } from "react-icons/bs";

const breadCrumbData:Array<BreadCrumbItemProps> = [
  {
    label: 'Home',
    link: '/'
  },
  {
    label: 'Trainings',
    link: '/trainings'
  },
  {
    label: '.Net + Angular',
  }];

function TrainingPage() {
  return (
    <>
      <BreadCrumb items={breadCrumbData} />
      <Box className="genz-wrapper">
        <Box>
          <Flex alignItems={'center'} mb={4}>
            <Heading lineHeight={1.1} fontWeight={600} fontSize="26px" flex={1}>
              <GradientText title=".Net + Angular" />
            </Heading>

            <Box w={'fit-content'}>
              <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 5, lg: 8 }}>
                <StatsCard title={'Duration'} stat={'6 months'} icon={<Icon as={BsStopwatch} w={4} h={4} />} />
                <StatsCard title={'Sessions'} stat={'500+'} icon={<Icon as={BsPersonVideo3} w={4} h={4} />} />
                <StatsCard title={'Language'} stat={'English'} icon={<Icon as={BsGlobeCentralSouthAsia} w={4} h={4} />} />
              </SimpleGrid>
            </Box>
          </Flex>

          <Text color={'gray.500'} mb={2}>The advantage of .NET is that it is a framework from Microsoft. What makes it powerful is its support for multiple languages like C#, VB.Net, etc. .NET offers functionality and features that support the development and running of Windows-based applications. In addition, it also offers connectivity with non-Windows platforms. It is a known fact that Windows is a widely used and popular O.S. platform. That’s the reason why .NET is so commonly used to develop software applications in the IT industry.</Text>
        </Box>
        <Flex direction={"row"} gap={8} my={8}>
          <Box flex="2">
            <Heading lineHeight={1.1} fontWeight={600} fontSize="26px">
              <GradientText title="Training Programs" />
            </Heading>

            <Accordion my="4" allowMultiple>
              <AccordionItem mb={"2"}>
                <AccordionButton
                  bgColor={'#FFF'}
                  color={'gray.500'}
                  py="2"
                  px="3"
                  border={'1px solid'}
                  borderColor={'blackAlpha.200'}
                  borderTopRadius={4}
                  fontSize={'20px'}
                  fontWeight={600}
                  _expanded={{ bg: "tomato", bgColor:"#962cb2", color:"#FFF" }}
                >
                  <Box as="span" flex="1" textAlign={"left"}>
                    Topic / Section 1
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel border={"1px solid"} borderColor={'blackAlpha.200'} borderTop={0} py="2" px="3" color={'gray.500'}>
                  <Box>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </Box>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem mb={"2"}>
                <AccordionButton
                  bgColor={'#FFF'}
                  color={'gray.500'}
                  py="2"
                  px="3"
                  border={'1px solid'}
                  borderColor={'blackAlpha.200'}
                  borderTopRadius={4}
                  fontSize={'20px'}
                  fontWeight={600}
                  _expanded={{ bg: "tomato", bgColor:"#962cb2", color:"#FFF" }}
                >
                  <Box as="span" flex="1" textAlign={"left"}>
                    Topic / Section 2
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel border={"1px solid"} borderColor={'blackAlpha.200'} borderTop={0} py="2" px="3" color={'gray.500'}>
                  <Box>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </Box>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem mb={"2"}>
                <AccordionButton
                  bgColor={'#FFF'}
                  color={'gray.500'}
                  py="2"
                  px="3"
                  border={'1px solid'}
                  borderColor={'blackAlpha.200'}
                  borderTopRadius={4}
                  fontSize={'20px'}
                  fontWeight={600}
                  _expanded={{ bg: "tomato", bgColor:"#962cb2", color:"#FFF" }}
                >
                  <Box as="span" flex="1" textAlign={"left"}>
                    Topic / Section 3
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel border={"1px solid"} borderColor={'blackAlpha.200'} borderTop={0} py="2" px="3" color={'gray.500'}>
                  <Box>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </Box>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem mb={"2"}>
                <AccordionButton
                  bgColor={'#FFF'}
                  color={'gray.500'}
                  py="2"
                  px="3"
                  border={'1px solid'}
                  borderColor={'blackAlpha.200'}
                  borderTopRadius={4}
                  fontSize={'20px'}
                  fontWeight={600}
                  _expanded={{ bg: "tomato", bgColor:"#962cb2", color:"#FFF" }}
                >
                  <Box as="span" flex="1" textAlign={"left"}>
                    Topic / Section 4
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel border={"1px solid"} borderColor={'blackAlpha.200'} borderTop={0} py="2" px="3" color={'gray.500'}>
                  <Box>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </Box>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem mb={"2"}>
                <AccordionButton
                  bgColor={'#FFF'}
                  color={'gray.500'}
                  py="2"
                  px="3"
                  border={'1px solid'}
                  borderColor={'blackAlpha.200'}
                  borderTopRadius={4}
                  fontSize={'20px'}
                  fontWeight={600}
                  _expanded={{ bg: "tomato", bgColor:"#962cb2", color:"#FFF" }}
                >
                  <Box as="span" flex="1" textAlign={"left"}>
                    Topic / Section 5
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel border={"1px solid"} borderColor={'blackAlpha.200'} borderTop={0} py="2" px="3" color={'gray.500'}>
                  <Box>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </Box>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem mb={"2"}>
                <AccordionButton
                  bgColor={'#FFF'}
                  color={'gray.500'}
                  py="2"
                  px="3"
                  border={'1px solid'}
                  borderColor={'blackAlpha.200'}
                  borderTopRadius={4}
                  fontSize={'20px'}
                  fontWeight={600}
                  _expanded={{ bg: "tomato", bgColor:"#962cb2", color:"#FFF" }}
                >
                  <Box as="span" flex="1" textAlign={"left"}>
                    Topic / Section 6
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel border={"1px solid"} borderColor={'blackAlpha.200'} borderTop={0} py="2" px="3" color={'gray.500'}>
                  <Box>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </Box>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem mb={"2"}>
                <AccordionButton
                  bgColor={'#FFF'}
                  color={'gray.500'}
                  py="2"
                  px="3"
                  border={'1px solid'}
                  borderColor={'blackAlpha.200'}
                  borderTopRadius={4}
                  fontSize={'20px'}
                  fontWeight={600}
                  _expanded={{ bg: "tomato", bgColor:"#962cb2", color:"#FFF" }}
                >
                  <Box as="span" flex="1" textAlign={"left"}>
                    Topic / Section 7
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel border={"1px solid"} borderColor={'blackAlpha.200'} borderTop={0} py="2" px="3" color={'gray.500'}>
                  <Box>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </Box>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem mb={"2"}>
                <AccordionButton
                  bgColor={'#FFF'}
                  color={'gray.500'}
                  py="2"
                  px="3"
                  border={'1px solid'}
                  borderColor={'blackAlpha.200'}
                  borderTopRadius={4}
                  fontSize={'20px'}
                  fontWeight={600}
                  _expanded={{ bg: "tomato", bgColor:"#962cb2", color:"#FFF" }}
                >
                  <Box as="span" flex="1" textAlign={"left"}>
                    Topic / Section 8
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel border={"1px solid"} borderColor={'blackAlpha.200'} borderTop={0} py="2" px="3" color={'gray.500'}>
                  <Box>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </Box>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem mb={"2"}>
                <AccordionButton
                  bgColor={'#FFF'}
                  color={'gray.500'}
                  py="2"
                  px="3"
                  border={'1px solid'}
                  borderColor={'blackAlpha.200'}
                  borderTopRadius={4}
                  fontSize={'20px'}
                  fontWeight={600}
                  _expanded={{ bg: "tomato", bgColor:"#962cb2", color:"#FFF" }}
                >
                  <Box as="span" flex="1" textAlign={"left"}>
                    Topic / Section 9
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel border={"1px solid"} borderColor={'blackAlpha.200'} borderTop={0} py="2" px="3" color={'gray.500'}>
                  <Box>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </Box>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem mb={"2"}>
                <AccordionButton
                  bgColor={'#FFF'}
                  color={'gray.500'}
                  py="2"
                  px="3"
                  border={'1px solid'}
                  borderColor={'blackAlpha.200'}
                  borderTopRadius={4}
                  fontSize={'20px'}
                  fontWeight={600}
                  _expanded={{ bg: "tomato", bgColor:"#962cb2", color:"#FFF" }}
                >
                  <Box as="span" flex="1" textAlign={"left"}>
                    Topic / Section 10
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel border={"1px solid"} borderColor={'blackAlpha.200'} borderTop={0} py="2" px="3" color={'gray.500'}>
                  <Box>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
          <Enquiry />
        </Flex>
      </Box>
    </>
  );
}

export default TrainingPage;
