import React from "react";
import "./App.css";
import { ChakraBaseProvider, ColorModeScript } from "@chakra-ui/react";
import { theme } from "./theme";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./AppRoutes";

function App() {
  return (


    <BrowserRouter>
      <ChakraBaseProvider theme={theme}>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <AppRoutes />
      </ChakraBaseProvider>
    </BrowserRouter>


  );
}

export default App;
