import * as React from "react";
import { Box, Stack, Heading, Text, Container, OrderedList, ListItem, SimpleGrid, StackDivider, useColorModeValue } from "@chakra-ui/react";
import { GradientText } from "../components/atoms";

interface Openings {
  role: string;
  exprience: string;
  skill: string;
  positions: number;
}

const CURRENT_OPENING_DATA: Array<any> = [
  {
    role: 'Software Engineer - React JS Developer',
    exprience: '1 -2',
    skill: 'React JS, JavaScript, TypeScript, Redux, CSS, ANTD/Chakra UI Components, Git, Agile',
    positions: 10
  },
  {
    role: 'Software Engineer - React Native Developer',
    exprience: '1-2',
    skill: 'ReactJS, React Native, Mobile Apps Development, Android, Redux, Redux Toolkit, Git',
    positions: 10
  },
  {
    role: 'Senior Software Engineer ',
    exprience: '3+',
    skill: 'Android/iOS, React Native/React JS, Redux, JavaScript, TypeScript, Git, Agile',
    positions: 2
  },
  {
    role: 'Trainee Engineers',
    exprience: 'Fresher/2023 Passouts',
    skill: 'OOPs, JavaScript/TypeScript, React JS/ React Native, Node JS',
    positions: 10
  }
];

const CurrentOpeningGrid = ({ role, exprience, skill, positions }: Openings) => {
  return (
    <Box bg={'#FFFFFF'} rounded="2xl" py={4}>
      <SimpleGrid justifyContent={'space-between'} columns={{ base: 1, md: 3 }} gap={'6'}>
        <Box>
          <Heading size="md" color={'brand.900'} fontWeight={'bold'}>{role}</Heading>
          <Text mt={1} fontSize={'sm'}>
            Required Experience {exprience} Years
          </Text>
        </Box>
        <Box>
          <Heading size="md" color={'brand.900'} fontWeight={'bold'}>Key Skills</Heading>
          <Text mt={1} fontSize={'sm'}>
            {skill}
          </Text>
        </Box>
        <Box>
          <Heading size="md" color={'brand.900'} fontWeight={'bold'}>Postions</Heading>
          <Text mt={1} fontSize={'sm'}>
            {positions}
          </Text>
        </Box>
      </SimpleGrid>
    </Box>
  );
}

function Careers() {
  return (
    <>
      <Box className='full-width-gray'>
        <Box className="genz-wrapper" py={8}>
          <Stack spacing={4} as={Container}>
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              fontSize={{ base: '3xl', sm: '3xl', lg: '4xl' }}
              mb={8}>
            <GradientText title="we are hiring" />
            </Heading>
          </Stack>
          <OrderedList>
            <ListItem>We have an open culture in our organization. Your opinions are welcome & valued equally.</ListItem>
            <ListItem>We Provide learning Opportunities to Newcomers as well as Experienced Professionals. We provide sponsorship for Industry Certifications.</ListItem>
            <ListItem>You will work with latest Technologies with State of Art Projects & Solve Challenging Problems.</ListItem>
          </OrderedList>
        </Box>
      </Box>

      <Box className="genz-wrapper">
        <Stack spacing={4} as={Container} >
          <Heading lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: '2xl', sm: '3xl', lg: '3xl' }}
            my={8}>
            <GradientText title="Current Openings" />
          </Heading>
        </Stack>

        <Stack
          spacing={4}
          divider={<StackDivider borderColor={useColorModeValue('gray.300', 'gray.700')} />}
          mb={8}>
          {CURRENT_OPENING_DATA.map((item, index) => (
            <CurrentOpeningGrid key={index} role={item.role} exprience={item.exprience} skill={item.skill} positions={item.positions} />
          ))}
        </Stack>
      </Box>
    </>
  );
}

export default Careers;
