import React from "react";
import { Routes, Route } from "react-router-dom";
import {
  HomePage,
  NotFoundPage,
  AboutUs,
  Contact,
  Testimonials,
  Placements,
  Trainings,
  TrainingDetails,
} from "./pages";
import { Layout } from "./components/templates";

//const Home = React.lazy(() => import("../components/pages/Home"));

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/trainings" element={<Trainings />} />
        <Route path="/training/:courseType/:courseName" element={<TrainingDetails/>} />
        <Route path="testimonials" element={<Testimonials />} />
        <Route path="placements" element={<Placements />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
