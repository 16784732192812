import { Box, Stack } from '@chakra-ui/react';
// import { FcMultipleDevices, FcMultipleSmartphones, FcEngineering, FcElectronics, FcAssistant, FcCommandLine, FcProcess, FcStatistics, FcSportsMode, } from 'react-icons/fc';
import { BannerWithBgImg, Trainings } from "../components/molecules";

// const SERVICE_ITEMS: Array<any> = [
//   {
//     icon: FcMultipleDevices,
//     title: "Custom Software Development",
//     text: "We offer custom software development solutions that helps your business to remain ahead of the competition.",
//     cardBgImg: "url('/images/bg.jpg')",
//     href: 'customapplication'
//   },
//   {
//     icon: FcMultipleSmartphones,
//     title: "Mobile Application Development",
//     text: "We assist companies in leveraging the power of technology by designing cutting-edge apps for iOS, Android and Hybrid platforms.",
//     cardBgImg: "url('/images/bg.jpg')",
//     href: 'mobileapplication'
//   },
//   {
//     icon: FcEngineering,
//     title: "Data Engineering and Analytics",
//     text: "We offer extensive technology support for data engineering needs like dashboards, data migration, data science.",
//     cardBgImg: "url('/images/bg.jpg')",
//     href: 'dataanalytics'
//   },
//   {
//     icon: FcProcess,
//     title: "Quality Engineering & Assurance",
//     text: "We offer automation ecosystem solution with AI capabilities to cover any possible flow and gain quality and speed.",
//     cardBgImg: "url('/images/bg.jpg')",
//     href: 'qualityenginnering'
//   },
//   {
//     icon: FcElectronics,
//     title: "AI Solutions",
//     text: "We offer AI driven solutions using Open AI, Generative AI for demanding customer needs",
//     cardBgImg: "url('/images/bg.jpg')",
//     href: 'aisolution'
//   },
//   {
//     icon: FcAssistant,
//     title: "Lets Discuss",
//     text: "Don't see what you are looking?",
//     cardBgImg: "url('/images/bg.jpg')",
//     href: 'mobileapplication'
//   }
// ];

// const PRODUCTS_ITEMS: Array<any> = [
//   {
//     icon: FcSportsMode,
//     title: "Sportify",
//     text: "One stop solution for all types of sports academies.",
//     href: "sportify",
//     cardBgImg: "url('/images/bg.jpg')",
//   },
//   {
//     icon: FcCommandLine,
//     title: "LiteCode",
//     text: "No Code Platform for all types of business needs",
//     href: "litecode",
//     cardBgImg: "url('/images/bg.jpg')",
//   },
//   {
//     icon: FcProcess,
//     title: "AutoQA",
//     text: "Automated QA Framework.",
//     href: "autoqa",
//     cardBgImg: "url('/images/bg.jpg')",
//   },
//   {
//     icon: FcStatistics,
//     title: "Data Forcast",
//     text: "Dynamic Data Analytics Tool.",
//     href: "dataforcast",
//     cardBgImg: "url('/images/bg.jpg')",
//   }
// ];

function Home() {
  return (
    <>
      <Box className="banner-section">
        <Box className="genz-wrapper">
          <BannerWithBgImg flexDirection="row-reverse"></BannerWithBgImg>
        </Box>
      </Box>
      <Stack
        className="genz-wrapper services-section"
        py={{ base: 20, md: 12 }}
      >
        <Trainings />
      </Stack>
    </>
  );
}

export default Home;
