import {
  Container,
  Stack,
  Flex,
  Heading,
  Text,
  Img,
} from '@chakra-ui/react';
import { GradientText } from '../atoms';
import chakraHero from '../../assets/header.jpeg';
const CallToActionWithVideo = (props: any) => {
  const { flexDirection } = props;
  return (
    <Container className='banner-with-bg-vdo' maxW="initial">
      <Stack
        align={'center'}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 16 }}
        direction={{ base: 'column-reverse', md: flexDirection ? flexDirection : 'row' }}>
        <Stack flex={1} spacing={{ base: 8, md: 10 }} bg={'blackAlpha.200'} rounded={'2xl'} className="banner-left-box">
          <Heading
            lineHeight={1}
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '3xl', lg: '3xl' }}>
            <GradientText as={'span'} title="Your Next Gen AI Solution Partner" />
          </Heading>
          <Text color={'brand.500'} textAlign={'justify'}>
            We are a cutting-edge technology company that specializes in artificial intelligence. We know the potential of AI in transforming various industries. We intend to be at the forefront of developing innovative AI solutions that help businesses and organizations achieve their goals.
            We offer a wide range of AI solutions, including natural language processing, machine learning, computer vision, and predictive analytics. These solutions can be applied to various industries, including healthcare, finance, retail, and manufacturing. We hold a proven track record of delivering high-quality solutions that help businesses improve their operations, increase efficiency, and reduce costs.
          </Text>
        </Stack>
        <Flex
          className='img-box'
          flex={1}
          justify={'center'}
          align={'center'}
          position={'relative'}
          w={'full'}>
          <Img src={chakraHero} />
        </Flex>
      </Stack>
    </Container>
  );
}

export default CallToActionWithVideo;
