import {
    Flex,
    Image,
    Text,
    Button,
    useMediaQuery,
    Heading,
    Box,
} from '@chakra-ui/react';
import React from 'react';
import teamImage from '../../assets/teamImage.jpg';
import { GradientText } from '../atoms';

const Services = () => {
  const [isLargerThanLG] = useMediaQuery("(min-width: 62em)");

    return (
        <>
            <Heading
                lineHeight={1.1}
                fontWeight={600}
                fontSize={{ base: '3xl', sm: '3xl', lg: '4xl' }}
                mb={8}>
                <GradientText title='Our Training Plans' />
            </Heading>

            <Flex
                id='trainingStrategy'
                width="full"
                minHeight="40vh"
                alignItems="center"
                py="6"
                justifyContent="center"
                flexDirection={isLargerThanLG ? 'row' : 'column'}
            >

                <Box
                    w={isLargerThanLG ? '40%' : 'full'}
                    mb={isLargerThanLG ? '0' : '6'}
                >
                    <Image src={teamImage} alt="Chakra Team" w="full" />
                </Box>
                <Box
                    w={isLargerThanLG ? '60%' : 'full'}
                    ml={isLargerThanLG ? '7' : '0'}
                >
                    <Text fontSize={isLargerThanLG ? '5xl' : '4xl'} fontWeight="bold">
                        We build, We revive
                    </Text>

                    <Text mb="6" opacity="0.8">
                        Your business needs to be in safe hands at all times. We ensure you
                        never run out of customers and not run at loss. We are trusted by over
                        500+ companies to deliver quality marketing campaigns using Digital
                        marketing & Offline marketing channels.
                    </Text>

                    <Button variant="solid"
                              bg="#244c70"
                              color="white"
                              size={'lg'}
                              width={'200px'}
                              _hover={{ bg: '#962cb2' }}>
                        CONTACT US
                    </Button>
                </Box>
            </Flex>
        </>
    );
};

export default Services;
