import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Icon,
  ListItem,
  UnorderedList,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import {
  BsStopwatch,
  BsPersonVideo3,
  BsGlobeCentralSouthAsia,
} from "react-icons/bs";
import { useParams } from "react-router";
import { BreadCrumb, GradientText } from "../components/atoms";
import { StatsCard } from "../components/molecules";
import Enquiry from "../components/molecules/Enquiry";
import { BreadCrumbItemProps } from "../types";
import { TRAINING_PROGRAMS } from "../constants";

function TrainingDetails() {
  const params = useParams();
  let courseType = params.courseType ? params.courseType : "";
  let courseName = params.courseName ? params.courseName : "";
  let courseType1 = courseType.replace("_", " ");

  const breadCrumbData: Array<BreadCrumbItemProps> = [
    {
      label: "Home",
      link: "/",
    },
    {
      label: "Trainings",
      link: "/trainings",
    },
    {
      label: courseType1,
    },
    {
      label: courseName,
    },
  ];

  let filteredObject = Object.keys(TRAINING_PROGRAMS)
    .filter((key) => key === courseType)
    .reduce((obj: any, key) => {
      return TRAINING_PROGRAMS[key as keyof typeof TRAINING_PROGRAMS];
    }, {} as typeof TRAINING_PROGRAMS);

  let courseObject = filteredObject.filter((ele: any) => {
    if (ele.label.trim() === courseName.trim()) {
      return ele;
    }
  });

  console.log(courseObject);
  return (
    <>
      <BreadCrumb items={breadCrumbData} />
      {courseObject[0].content && courseObject[0].programs ? (
        <Box className="genz-wrapper">
          <Box>
            <Flex alignItems={"center"} mb={4}>
              <Heading
                lineHeight={1.1}
                fontWeight={600}
                fontSize="26px"
                flex={1}
              >
                <GradientText title={courseName} />
              </Heading>

              <Box w={"fit-content"}>
                <SimpleGrid
                  columns={{ base: 1, md: 3 }}
                  spacing={{ base: 5, lg: 8 }}
                >
                  <StatsCard
                    title={"Duration"}
                    stat={courseObject[0].details?.duration}
                    icon={<Icon as={BsStopwatch} w={4} h={4} />}
                  />
                  <StatsCard
                    title={"Sessions"}
                    stat={courseObject[0].details?.sessions}
                    icon={<Icon as={BsPersonVideo3} w={4} h={4} />}
                  />
                  <StatsCard
                    title={"Language"}
                    stat={courseObject[0].details?.language}
                    icon={<Icon as={BsGlobeCentralSouthAsia} w={4} h={4} />}
                  />
                </SimpleGrid>
              </Box>
            </Flex>

            {courseObject[0]?.content?.map((ele: any) => (
              <Text color={"gray.500"} mb={3}>
                {ele}
              </Text>
            ))}
          </Box>
          <Flex direction={"row"} gap={8} my={8}>
            <Box flex="2">
              <Heading lineHeight={1.1} fontWeight={600} fontSize="26px">
                <GradientText title="Training Programs" />
              </Heading>

              {courseObject[0]?.programs?.map((ele: any) => (
                <Accordion my="4" allowMultiple>
                  <AccordionItem mb={"2"}>
                    <AccordionButton
                      bgColor={"#FFF"}
                      color={"gray.500"}
                      py="2"
                      px="3"
                      border={"1px solid"}
                      borderColor={"blackAlpha.200"}
                      borderTopRadius={4}
                      fontSize={"20px"}
                      fontWeight={600}
                      _expanded={{
                        bg: "tomato",
                        bgColor: "#962cb2",
                        color: "#FFF",
                      }}
                    >
                      <Box as="span" flex="1" textAlign={"left"}>
                        {ele.title}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel
                      border={"1px solid"}
                      borderColor={"blackAlpha.200"}
                      borderTop={0}
                      py="2"
                      px="3"
                      color={"gray.500"}
                    >
                      <UnorderedList>
                        {ele?.content?.map((e: any) => (
                          <ListItem>{e}</ListItem>
                        ))}
                      </UnorderedList>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              ))}
            </Box>
            <Enquiry />
          </Flex>
        </Box>
      ) : (
        <Box className="genz-wrapper" textAlign={"center"} my="4">
          <Flex direction={"row"} gap={8} my={8}>
            <Box flex="2">
              <Text fontSize={"20px"}>
                The {courseName} training details are not available!
              </Text>
              <Text>
                Please contact us at enquiry@genzlearnings.com for additional
                information.
              </Text>
            </Box>
            <Enquiry />
          </Flex>
        </Box>
      )}
    </>
  );
}

export default TrainingDetails;
