import * as React from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Heading,
  Text,
  IconButton,
  Button,
  VStack,
  HStack,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
  Container,
  Wrap,
  WrapItem,
  Link,
} from "@chakra-ui/react";
import { MdPhone, MdEmail, MdLocationOn, MdOutlineEmail } from "react-icons/md";
import { BsFacebook, BsInstagram, BsLinkedin, BsPerson } from "react-icons/bs";
import { GradientText } from "../components/atoms";

function Contact() {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  function onSubmit(values: any) {
    return new Promise<void>((resolve) => {
      console.log("Contact form submitted successfully !!!! ", values);
      resolve();
    });
  }

  return (
    <>
      <Container
        mt={0}
        centerContent
        overflow="hidden"
        className="genz-wrapper"
      >
        <Box maxW={"100%"} w={"100%"}>
          <Box
            bg={'blackAlpha.200'}
            borderRadius="lg"
            my={{ sm: 4, md: 16, lg: 10 }}
            p={{ sm: 5, md: 5, lg: 16 }}
          >
            <Box>
              <Wrap>
                <WrapItem flex={1}>
                  <Box m={4}>
                    <Heading
                      lineHeight={1.1}
                      fontWeight={600}
                      fontSize={{ base: "3xl", sm: "3xl", lg: "4xl" }}
                    >
                      <GradientText title="Contact" />
                    </Heading>
                    <Text mt={{ sm: 3, md: 3, lg: 5 }} fontWeight={600}>
                      Fill up the form below to contact
                    </Text>
                    <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                      <VStack pl={0} spacing={3} alignItems="flex-start">
                        <Button
                          justifyContent="flex-start"
                          size="md"
                          height="48px"
                          width="300px"
                          variant="ghost"
                          color="#244c70"
                          border='2px solid #edf2f7'
                          _hover={{ border: '2px solid #962cb2' }}
                          leftIcon={<MdPhone color="#962cb2" size="20px" />}>
                          +91-9284027085
                        </Button>
                        <Button
                          justifyContent="flex-start"
                          size="md"
                          height="48px"
                          width="300px"
                          variant="ghost"
                          color="#244c70"
                          border='2px solid #edf2f7'
                          _hover={{ border: '2px solid #962cb2' }}
                          leftIcon={<MdEmail color="#962cb2" size="20px" />}>
                          enquiry@genzlearnings.com
                        </Button>
                        <Button
                          justifyContent="flex-start"
                          size="md"
                          height="48px"
                          width="300px"
                          variant="ghost"
                          color="#244c70"
                          border='2px solid #edf2f7'
                          _hover={{ border: '2px solid #962cb2' }}
                          leftIcon={<MdLocationOn color="#962cb2" size="20px" />}>
                          Pune, India
                        </Button>
                      </VStack>
                    </Box>
                    <HStack
                      mt={{ lg: 10, md: 10 }}
                      spacing={5}
                      px={5}
                      alignItems="flex-start"
                    >
                      <Link
                        href={
                          "https://www.facebook.com/profile.php?id=100091413444953"
                        }
                      >
                        <IconButton
                          aria-label="facebook"
                          variant="ghost"
                          size="lg"
                          isRound={true}
                          color="#244c70"
                          _hover={{ bg: '#962cb2' }}
                          icon={<BsFacebook size="28px" />}
                        />
                      </Link>
                      <Link href={"https://www.instagram.com/genzailearnings/"}>
                        <IconButton
                          aria-label="github"
                          variant="ghost"
                          size="lg"
                          isRound={true}
                          color="#244c70"
                          _hover={{ bg: '#962cb2' }}
                          icon={<BsInstagram size="28px" />}
                        />
                      </Link>
                      <Link href={"https://www.linkedin.com/company/92525791"}>
                        <IconButton
                          aria-label="linkedin"
                          variant="ghost"
                          size="lg"
                          isRound={true}
                          color="#244c70"
                          _hover={{ bg: '#962cb2' }}
                          icon={<BsLinkedin size="28px" />}
                        />
                      </Link>
                    </HStack>
                  </Box>
                </WrapItem>
                <WrapItem flex={1}>
                  <Box bg="white" borderRadius="lg" w={"100%"} mx={4}>
                    <Box m={8} color="#0B0E3F">
                      <VStack spacing={5} alignItems={"flex-start"}>
                        <form
                          className="contact-form"
                          onSubmit={handleSubmit(onSubmit)}
                        >
                          <FormControl
                            w={"100%"}
                            isInvalid={errors.name ? true : false}
                          >
                            <FormLabel
                              htmlFor="name"
                              marginBottom={"8px"}
                              fontWeight={600}
                            >
                              Your Name
                            </FormLabel>
                            <InputGroup borderColor="#E0E1E7">
                              <InputLeftElement
                                pointerEvents="none"
                                padding={"16px"}
                                children={<BsPerson color="gray.800" />}
                              />
                              <Input
                                id="name"
                                type="text"
                                paddingLeft={"48px"}
                                paddingRight={"16px"}
                                w={"calc(100% - 16px)"}
                                border={"1px solid #cbd5e0"}
                                h={12}
                                borderRadius={"0.375rem"}
                                placeholder="Full Name"
                                {...register("name", {
                                  required: "Name is required",
                                  minLength: {
                                    value: 4,
                                    message: "Minimum length should be 4",
                                  },
                                })}
                              />
                            </InputGroup>
                            <FormErrorMessage>
                              {errors.name && errors.name.message?.toString()}
                            </FormErrorMessage>
                          </FormControl>

                          <FormControl
                            w={"100%"}
                            isInvalid={errors.email ? true : false}
                          >
                            <FormLabel
                              htmlFor="email"
                              marginBottom={"8px"}
                              fontWeight={600}
                            >
                              Mail
                            </FormLabel>
                            <InputGroup borderColor="#E0E1E7">
                              <InputLeftElement
                                padding={"16px"}
                                pointerEvents="none"
                                children={<MdOutlineEmail color="gray.800" />}
                              />
                              <Input
                                id="email"
                                type="text"
                                paddingLeft={"48px"}
                                paddingRight={"16px"}
                                w={"calc(100% - 16px)"}
                                border={"1px solid #cbd5e0"}
                                h={12}
                                borderRadius={"0.375rem"}
                                placeholder="Email Address"
                                {...register("email", {
                                  required: "Email is required",
                                  minLength: {
                                    value: 10,
                                    message: "Minimum length should be 10",
                                  },
                                  maxLength: {
                                    value: 100,
                                    message: "Minimum length should be 100",
                                  },
                                  pattern: {
                                    value:
                                      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i,
                                    message: "Please enter valid email address",
                                  },
                                })}
                              />
                            </InputGroup>
                            <FormErrorMessage>
                              {errors.email && errors.email.message?.toString()}
                            </FormErrorMessage>
                          </FormControl>

                          <FormControl
                            w={"100%"}
                            isInvalid={errors.message ? true : false}
                          >
                            <FormLabel
                              htmlFor="message"
                              marginBottom={"8px"}
                              fontWeight={600}
                            >
                              Message
                            </FormLabel>
                            <Textarea
                              id="message"
                              p={"16px"}
                              w={"100%"}
                              border={"1px solid #cbd5e0"}
                              h={24}
                              borderRadius={"0.375rem"}
                              placeholder="Your Message"
                              {...register("message", {
                                required: "Message is required",
                                minLength: {
                                  value: 10,
                                  message: "Minimum length should be 10",
                                },
                                maxLength: {
                                  value: 200,
                                  message: "Minimum length should be 200",
                                },
                              })}
                            />
                            <FormErrorMessage>
                              {errors.message &&
                                errors.message.message?.toString()}
                            </FormErrorMessage>
                          </FormControl>

                          <FormControl float="right">
                            <Button
                              variant="solid"
                              bg="#244c70"
                              color="white"
                              _hover={{ bg: '#962cb2' }}
                              isLoading={isSubmitting} type='submit'
                            >
                              Enquire Now
                            </Button>
                          </FormControl>
                        </form>
                      </VStack>
                    </Box>
                  </Box>
                </WrapItem>
              </Wrap>
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default Contact;
