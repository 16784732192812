import React from 'react';
import { Testimonials } from '../components/molecules';

function TestimonialsPage() {
    return (
        <>
            <Testimonials />
        </>
    );
}

export default TestimonialsPage;