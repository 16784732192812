import { Text } from "@chakra-ui/react";

const GradientText = (props: any) => {
  const { title } = props;
  return (
    <Text as={'span'}
      // bgGradient='linear(to-l, #962cb2, #00a0d2)'
      bgGradient='linear(to-l, #00a0d2, #962cb2)'
      bgClip='text'
    >
      {title}
    </Text>
  );
}

export default GradientText;