import {
  useColorModeValue,
  Stat,
  StatLabel,
  StatNumber,
  Icon
} from '@chakra-ui/react';
import { ReactElement } from 'react';

export interface StatsCardProps {
  title: string;
  stat: string;
  icon?: ReactElement
}

export function StatsCard(props: StatsCardProps) {
  const { title, stat, icon } = props;
  return (
    <Stat
      p={'3'}
      shadow={'xl'}
      border={'1px solid'}
      borderColor={useColorModeValue('gray.300', 'gray.700')}
      rounded={'2xl'}
      bg={'#FFFFFF'}>
      <StatNumber fontSize={'20px'} fontWeight={'bold'} color={'brand.900'}>
        {icon} {stat}
      </StatNumber>
      {/* <StatLabel fontWeight={'medium'} isTruncated color={'brand.900'} verticalAlign={'center'}>
      {icon} {title}
      </StatLabel> */}
    </Stat>
  );
}
