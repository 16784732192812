import React from "react";
import {
  Text,
  Collapse,
  Box,
} from "@chakra-ui/react";

import { DesktopNav } from "../molecules/navbar/Navbar";
import { MobileNav } from "../molecules/navbar/Navbar";
import { Outlet, Link } from "react-router-dom";
import { GradientText } from "../atoms";

const Logo = () => {
  return (
    <Box>
      <GradientText title='GenZ Gurukul' />
    </Box>
  );
};
const Layout = () => {
  return (
    <>
      <Box className="genz-navbar">
        <Box className="genz-navbar-wrapper">
          <Box className="logo">
            <Link to="/">
              <Logo />
            </Link>
          </Box>

          <Box className="genz-navbar-menu">
            <DesktopNav />
          </Box>
          <Box className="genz-navbar-menu-mob">
            <Collapse in={true} animateOpacity>
              <MobileNav />
            </Collapse>
          </Box>
        </Box>
      </Box>
      <Box>
        <Outlet />
      </Box>

      <Box className="genz-footer">
        <Box className="genz-footer-wrapper">
          <Text className="copyright">
            Copyright © 2023 GenZ Gurukul - All Rights Reserved.
          </Text>
        </Box>
      </Box>
    </>
  );
};

export default Layout;
