export const NAV_ITEMS = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "Training Programs",
    children: [],
  },
  {
    label: "Student Speaks",
    href: "/testimonials",
    children: [],
  },
  {
    label: "Placements",
    href: "/placements",
    children: [],
  },
  {
    label: "Contact",
    href: "/contact",
    children: [],
  },
];

export const TRAINING_PROGRAMS = {
  fullstack_development: [
    {
      label: ".Net + Angular",
      details: {
        duration: "6 months",
        sessions: "500+",
        language: "English",
      },
      content: [
        "The advantage of .NET is that it is a framework from Microsoft. What makes it powerful is its support for multiple languages like C#, VB.Net, etc. .NET offers functionality and features that support the development and running of Windows-based applications. In addition, it also offers connectivity with non-Windows platforms.",
        "It is a known fact that Windows is a widely used and popular O.S. platform. That is the reason why .NET is so commonly used to develop software applications in the IT industry.",
      ],
      programs: [
        {
          title: "Basic",
          content: [
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
          ],
        },
        {
          title: "Dot Net Core",
          content: [
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
          ],
        },
        {
          title: "Angular",
          content: [
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
          ],
        },
      ],
    },
    {
      label: "NodeJS + React",
    },
  ],
  quality_assurance: [
    {
      label: "Manual Testing",
    },
    {
      label: "Automation",
    },
  ],
  data_engineering: [
    {
      label: "RDBMS- MSSQL, MySQL",
    },
    {
      label: "RDBMS- Postgress, MongoDB",
    },
  ],
  devOps: [
    {
      label: "Continuous Integrations",
    },
    {
      label: "Continuous Deployment",
    },
  ],
};

export const TRAINING_TYPES = [
  "Application Development",
  "QA",
  "Data Engineering",
  "DevOps",
  "Product Engineering",
];
