import React from 'react';
import { Box } from '@chakra-ui/react';
import { TrainingStrategy } from '../components/molecules';

function TrainingStrategyPage() {
    return (
        <>
            <Box className="genz-wrapper">
                <TrainingStrategy />
            </Box>
        </>
    );
}

export default TrainingStrategyPage;