
import { Text } from "@chakra-ui/react";
import { NavLinkProps } from "../../types";

const NavLink = ({ text, linkColor, linkHoverColor }: NavLinkProps) => (
    <Text
        p={0.5}
        //as={navItem.href ?? "#"}
        fontSize={"md"}
        fontWeight={900}
        color={linkColor}
        _hover={{
            textDecoration: "underline",
            color: linkHoverColor,
        }}
    >
        {text}
    </Text>
)

export default NavLink;