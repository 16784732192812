import { Box, Heading } from '@chakra-ui/react';
import { memo } from "react";
import { Link } from "react-router-dom";

const NotFound = memo(() => {
  return (
    <Box className='genz-wrapper' textAlign={'center'} my="4">
      <Heading size='lg' fontSize='50px' mb={4}>
        Nothing to see here!
      </Heading>
      <Link className='link' to="/">Go to the home page</Link>
    </Box>
  );
});

export default NotFound;
