import { Flex, Text, Heading, SimpleGrid } from "@chakra-ui/react";
import { Icon } from "@chakra-ui/react";
import {
  BsStack,
  BsFillClipboardDataFill,
  BsFillPhoneFill,
  BsFillPatchCheckFill,
  BsInfinity,
  BsPersonVideo3,
} from "react-icons/bs";
import React from "react";
import { GradientText } from "../atoms";

const Trainings = () => {
  // const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');
  const array = [
    {
      id: 1,
      text: "Fullstack Development",
      icon: BsStack,
    },
    {
      id: 2,
      text: "Data Analytics",
      icon: BsFillClipboardDataFill,
    },
    {
      id: 3,
      text: "Mobile App Development",
      icon: BsFillPhoneFill,
    },
    {
      id: 4,
      text: "QA - Manual/Automation",
      icon: BsFillPatchCheckFill,
    },
    {
      id: 5,
      text: "DevOps",
      icon: BsInfinity,
    },
    {
      id: 6,
      text: "Interpersonal Trainings",
      icon: BsPersonVideo3,
    },
  ];
  return (
    <>
      <Heading
        lineHeight={1.1}
        fontWeight={600}
        fontSize={{ base: "3xl", sm: "3xl", lg: "4xl" }}
        mb={8}
      >
        <GradientText title="Trainings" />
      </Heading>

      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10} mb={8}>
        {array.map((arr) => (
          <Flex
            key={arr.id}
            height="150px"
            bg="blackAlpha.200"
            shadow="md"
            p="5"
            borderRadius="md"
            alignItems={"center"}
            justifyContent={"center"}
            border="1px solid #C4DDFF"
            _hover={{
              cursor: "pointer",
              backgroundColor: "#5e6681",
              color: "#fff",
              boxShadow: "0 8px 24px 0 rgb(16 39 112 / 20%)",
              transform: "scale(1.05)",
              transition: "transform 0.3s ease-in-out",
            }}
          >
            <Icon as={arr.icon} w={10} h={10} mr={"8px"} />
            <Text fontSize={"20px"} as={"span"}>
              {arr.text}
            </Text>
          </Flex>
        ))}
      </SimpleGrid>
    </>
  );
};

export default Trainings;
