import { extendBaseTheme } from '@chakra-ui/react'
import chakraTheme from '@chakra-ui/theme'
const { Button } = chakraTheme.components

const colors = {
    brand: {
      900: '#244c70',
      800: '#4a6a88',
      700: '#2a69ac',
    },
    font:{
      900:'#ffb26c'
    }
  }
  
  
  export const theme = extendBaseTheme({
    colors,
    components: {
      Button,
    },
  })
