import firebase from 'firebase/compat/app'
import "firebase/firestore";
import { getFirestore } from 'firebase/firestore';
import { initializeApp } from "firebase/app";


const firebaseConfig1 = {
    apiKey: "AIzaSyCoeVfDXv5c6O0auBApoNcd0wePgYb5rTE",
    authDomain: "react-firebase-crud-de170.firebaseapp.com",
    databaseURL: "https://react-firebase-crud-de170.firebaseio.com",
    projectId: "react-firebase-crud-de170",
    storageBucket: "react-firebase-crud-de170.appspot.com",
    messagingSenderId: "778361328727",
    appId: "1:778361328727:web:dd9a2dec582b638f15ff12"
};

const firebaseConfig = {
    apiKey: "AIzaSyDAOZ_ZF30YnSfX9m3D1JI4PARTeQpWz1M",
    authDomain: "genzlearning.firebaseapp.com",
    projectId: "genzlearning",
    storageBucket: "genzlearning.appspot.com",
    messagingSenderId: "500969127194",
    appId: "1:500969127194:web:8844bc2c4cc0a3fd2fc0a3",
    measurementId: "G-S59EJEL4FX",
    databaseURL: "https://genzlearning.firebaseio.com"
};
// Initialize Firebase
const app =
    firebase.initializeApp(firebaseConfig);
const db = getFirestore(app);


export { firebase, db };
