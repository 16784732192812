
import { Box, Heading, useColorModeValue, Text } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";


interface TrainingMenuCardProps {
    trainings: Array<{ label: string }>
    heading: string
}

const TrainingMenuCard = (props: TrainingMenuCardProps) => {
    const { heading, trainings } = props;
    const linkColor = useColorModeValue("brand.900", "brand.900");
    return (
        <Box p={3} w={400}  {...props} >
            <Heading color={linkColor} fontSize="xl" fontWeight={600} textTransform={'capitalize'}>{ heading.replace('_',' ')}</Heading>
            {trainings.map((p) => (
                <Text key={p.label} _hover={{ color: '#00a0d2' }}>
                    <RouterLink to={"training/"+heading+"/"+p.label}>
                        {p.label}
                    </RouterLink>
                </Text>
            ))}
        </Box>
    );
}
export default TrainingMenuCard;