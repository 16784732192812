import React from 'react';
import {
    Flex,
    Text,
    Avatar,
    Box,
    SimpleGrid,
    Icon,
} from '@chakra-ui/react';
import { BreadCrumb } from '../atoms';
import { BreadCrumbItemProps } from '../../types';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
import KarlBrightonKadex from '../../assets/KarlBrightonKadex.jpg';
import KrishnaBellsGoogle from '../../assets/KrishnaBellsGoogle.jpg';

const Testimonials = () => {
    const breadCrumbData:Array<BreadCrumbItemProps> = [
        {
          label: 'Home',
          link: '/'
        },
        {
          label: 'Success Stories',
        }
      ];
    const testimonialsData = [
        {
            id: '1',
            message: 'The team perfectly fit the specialized skill set required. They focused on the most essential features helping us launch the platform eight months faster than planned.',
            userImagePath: KarlBrightonKadex,
            userName: 'Karl Brighton',
            userDesignation: 'Software Engineer at Kadex'
        },
        {
            id: '2',
            message: 'We needed to automate our entire onboarding process. The team came in and built out the whole journey. Since going live, user retention has gone through the roof!',
            userImagePath: KrishnaBellsGoogle,
            userName: 'Krishna Bells',
            userDesignation: 'Product Manager at Google'
        },
        {
            id: '3',
            message: 'The team perfectly fit the specialized skill set required. They focused on the most essential features helping us launch the platform eight months faster than planned.',
            userImagePath: KarlBrightonKadex,
            userName: 'Karl Brighton',
            userDesignation: 'Software Engineer at Kadex'
        },
        {
            id: '4',
            message: 'We needed to automate our entire onboarding process. The team came in and built out the whole journey. Since going live, user retention has gone through the roof!',
            userImagePath: KrishnaBellsGoogle,
            userName: 'Krishna Bells',
            userDesignation: 'Product Manager at Google'
        }
    ]

    return (
        <>
            <BreadCrumb items={breadCrumbData} />
            <Box className="genz-wrapper">
                <Text fontSize={'20px'} fontWeight={600} mb={'8px'}>Welcome to our Testimonial Page!</Text>
                <Text fontSize={'20px'} mb={'8px'}>At GenZ Gurukul, we are dedicated to providing top-notch IT training and empowering individuals with the knowledge and skills they need to excel in the rapidly evolving tech industry. We take immense pride in the success stories and positive experiences shared by our students. Below, you'll find a selection of testimonials from some of our satisfied learners.</Text>
                <Box px="8" py="8" my={8} bg={'blackAlpha.200'} rounded={'2xl'}>
                    <SimpleGrid columns={{ base: 1, sm:1, md: 2, lg: 2 }} spacing={10}>
                        {testimonialsData.map((ele,index) => (
                            <Flex
                                key={ele.id}
                                shadow="md"
                                flexDirection="column"
                                border="2px solid #962cb2"
                                borderRadius="md"
                                bg="#FFF"
                            >
                                <Box position="relative">
                                    <Text p={8} fontWeight={600} textColor={'#00a0d2'}>
                                        {ele.message}
                                    </Text>
                                    <Box
                                        position="absolute"
                                        top="0px"
                                        left="8px"
                                        color="blackAlpha.200"
                                        fontSize="3xl"
                                    >
                                        <Icon as={FaQuoteLeft} />
                                    </Box>
                                    <Box
                                        position="absolute"
                                        bottom="0px"
                                        right="8px"
                                        color="blackAlpha.200"
                                        fontSize="3xl"
                                    >
                                        <Icon as={FaQuoteRight} />
                                    </Box>
                                </Box>
                                
                                <Flex direction={'row'} alignItems={"center"} p={4} bg={"#962cb2"}>
                                    <Avatar
                                        maxW={"100px"}
                                        mr={8}
                                        name={ele.userName}
                                        src={ele.userImagePath}
                                        border="4px solid #FFF"
                                    />
                                    <Box textColor={'#FFF'}>
                                        <Text fontWeight={600}>{ele.userName}</Text>
                                        <Text fontSize="sm" opacity="0.7">
                                            {ele.userDesignation}
                                        </Text>
                                    </Box>
                                </Flex>
                            </Flex>
                        ))}
                    </SimpleGrid>
                </Box>
                <Text fontSize={'20px'} mb={'8px'}>We extend our heartfelt gratitude to all the students who have generously shared their experiences with us. Your testimonials inspire us to continue delivering exceptional training and support to aspiring IT professionals.</Text>
                <Text fontSize={'20px'} mb={'8px'}>If you're a current or former student and would like to contribute your testimonial, we would love to hear from you! Your feedback not only helps us enhance our programs but also assists prospective learners in making informed decisions.</Text>
                <Text fontSize={'20px'} mb={'8px'}>Please note that all testimonials are authentic and unedited, reflecting the genuine experiences of our students. We believe in transparency and strive to maintain the highest standards of integrity.</Text>
                <Text fontSize={'20px'} mb={'20px'}>Thank you for choosing GenZ Gurukul as your partner on your IT learning journey. We are committed to your success and look forward to helping you achieve your professional goals.</Text>
            </Box>
        </>
    );
};
export default Testimonials;
